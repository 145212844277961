import './App.css';
import {Button, TextField} from '@material-ui/core'; //importing material ui component
import Header from './NavBar.js';

function App() {
  return (
    <div className="App">
      <Header/>
      <Button color="primary" variant="contained"> Press me </Button>
      <br /><br />
      <TextField display="block" id="name" label="Name" variant="outlined" />
      
    </div>
  );
}

export default App;
